@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.payment-page {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  width: 100%;
}

.payment-page__form {
  margin: 0 auto;
}

.payment-page__fixed-footer {
  background-color: $color-black-0;
  bottom: 0;
  position: fixed;
  z-index: 3;

  @include tablet() {
    position: static;
  }
}
