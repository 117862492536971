@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.login-page {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: calc(var(--vh, 1vh) * 100);
}

.login-page__background-wrapper {
  display: grid;
  overflow: hidden;
  position: relative;

  @include tablet-max() {
    max-height: 220px;

    &::before {
      backdrop-filter: blur(2.5px);
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(0, 0, 0, 0.15) 0%,
        rgba(0, 0, 0, 0.24) 100%
      );
      content: "";
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
}

.login-page__background-image {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: 0 50%;
  position: absolute;
  right: 0;
  top: 0;
  width: 150%;
  z-index: -1;
}

.login-page__wrapper {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 220px;

  @include tablet() {
    grid-auto-flow: column;
    grid-template-columns: 1.4fr 0.6fr;
    grid-template-rows: none;
    padding: 0;
  }
}

.login-page__form-wrapper {
  background-color: white;

  @include tablet() {
    box-sizing: border-box;
    display: grid;
    gap: 37px;
    grid-template-rows: minmax(200px, min-content) min-content;
    justify-items: center;
    min-width: 480px;
    padding: 60px 40px 0;
  }
}

.login-page__logo {
  display: none;

  @include tablet() {
    display: grid;
  }
}

.login-page__logo-mobile {
  display: grid;
  z-index: 3;

  @include tablet() {
    display: none;
  }
}

.login-page__footer-logo {
  display: none;

  @include tablet() {
    bottom: 32px;
    display: grid;
    gap: 8px;
    grid-column: 1;
    left: 32px;
    position: absolute;
  }
}

.login-page__links {
  display: none;

  @include tablet() {
    align-self: end;
    display: grid;
  }
}

.login-page__footer {
  display: grid;

  @include tablet() {
    display: none;
  }
}
