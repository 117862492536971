@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.main-page {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.main-page__wrapper {
  height: 100%;
}

.main-page__content {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 680px;
  padding: 16px 16px 24px;
  width: 100vw;

  @include tablet() {
    padding: 22px 0 32px;
  }
}

.main-page__content-section {
  padding-top: 18px;

  @include tablet() {
    padding-top: 32px;
  }
}

.main-page__content-section_payments {
  padding-bottom: 28px;
}

.main-page__content-section_summary {
  padding-left: 16px;
  padding-right: 16px;

  @include tablet() {
    padding-left: 0;
    padding-right: 0;
  }
}
