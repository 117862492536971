@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/text.scss";

.payment-details__checkbox {
  display: none;
}

.payment-details__label {
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
  justify-content: space-between;

  @include tablet() {
    display: none;
  }
}

.payment-details__title {
  @include textAccent-bold();
}

.payment-details__arrow-wrapper {
  background: $color-black-6;
  border-radius: 16px;
  box-sizing: border-box;
  display: inline-grid;
  height: 32px;
  padding: 6px;
  width: 32px;
}

.payment-details__arrow {
  color: $color-theme-primary;
  height: 20px;
  transform: rotate(90deg);
  transition: transform $transition-time ease;
  width: 20px;

  .payment-details__checkbox:checked + .payment-details__label & {
    transform: rotate(-90deg);
  }
}

.payment-details__content {
  display: none;

  .payment-details__checkbox:checked ~ & {
    display: block;
  }

  @include tablet() {
    display: block;
  }
}
