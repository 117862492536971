@import "src/assets/styles/mixins/breakpoints.scss";

.order-summary {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 24px;
  margin: 0 auto;
  max-width: 520px;

  @include tablet() {
    gap: 40px;
  }
}
