@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";

.checkbox {
  position: relative;
  z-index: 2;
}

.checkbox__input {
  height: 20px;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.checkbox__label {
  @include textBody();
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 16px;
  grid-template-columns: 24px auto;
  line-height: 20px;
}

.checkbox__icon-wrapper {
  display: grid;
  padding: 2px;
}

.checkbox__icon {
  background: $color-black-0;
  border: 1px solid $color-black-35;
  border-radius: 4px;
  box-sizing: border-box;
  height: 20px;
  justify-self: center;
  position: relative;
  transition: border-color $transition-time ease-in-out;
  width: 20px;

  &::before {
    border-radius: 50%;
    content: "";
    display: block;
    height: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all ease-in-out $transition-time;
    width: 40px;
    z-index: -1;
  }
}

.checkbox__icon:hover:not(.checkbox_disabled) {
  border-color: $color-black-60;

  &::before {
    background: $color-black-5;
  }
}

.checkbox__input:focus-visible + .checkbox__label {
  .checkbox__icon {
    border-color: $color-black-60;

    &::before {
      background-color: $color-black-5;
    }
  }
}

.checkbox__input + .checkbox__label .checkbox__icon::after {
  border: 1px solid $color-black-75;
  border-left: 0;
  border-top: 0;
  content: "";
  display: block;
  height: 9px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 2px;
  transform: rotate(45deg);
  transition: all ease-in-out $transition-time;
  width: 5px;
  z-index: 2;
}

.checkbox__input:checked + .checkbox__label .checkbox__icon::after {
  opacity: 1;
}

.checkbox__input:disabled {
  & + .checkbox__label {
    color: $color-black-30;
    cursor: default;
  }

  & + .checkbox__label .checkbox__icon {
    background-color: $color-black-5;
    border-color: $color-black-10;
  }

  &:checked + .checkbox__label .checkbox__icon::after {
    opacity: 0.3;
  }
}

.checkbox_multiple {
  .checkbox__input + .checkbox__label .checkbox__icon::after {
    background-color: $color-black-75;
    border: none;
    border-radius: 1px;
    height: 8px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
  }
}
