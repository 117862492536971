@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";

@keyframes spinner-spin {
  0% {
    box-shadow: 15px 15px $color-theme-primary,
      -15px 15px $color-theme-secondary, -15px -15px $color-theme-primary,
      15px -15px $color-theme-secondary;
  }

  25% {
    box-shadow: -15px 15px $color-theme-secondary,
      -15px -15px $color-theme-primary, 15px -15px $color-theme-secondary,
      15px 15px $color-theme-primary;
  }

  50% {
    box-shadow: -15px -15px $color-theme-primary,
      15px -15px $color-theme-secondary, 15px 15px $color-theme-primary,
      -15px 15px $color-theme-secondary;
  }

  75% {
    box-shadow: 15px -15px $color-theme-secondary,
      15px 15px $color-theme-primary, -15px 15px $color-theme-secondary,
      -15px -15px $color-theme-primary;
  }

  100% {
    box-shadow: 15px 15px $color-theme-primary,
      -15px 15px $color-theme-secondary, -15px -15px $color-theme-primary,
      15px -15px $color-theme-secondary;
  }
}

.processing-page {
  background-color: $color-black-0;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
}

.processing-page__content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.processing-page__text {
  @include textSubtitle();
  line-height: 32px;
  margin: 0;
  text-align: center;
}

.processing-page__spinner {
  min-height: 96px;
  position: relative;
}

.processing-page__spinner-icon {
  animation: spinner-spin ease infinite 4.6s;
  border-radius: 100%;
  bottom: 0;
  box-shadow: 15px 15px $color-theme-primary, -15px 15px $color-theme-secondary,
    -15px -15px $color-theme-primary, 15px -15px $color-theme-secondary;
  height: 15px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
}
