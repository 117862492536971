@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.footer {
  align-items: end;
  align-self: end;
  background-color: $color-black-0;
  border-top: 1px solid $color-black-10;
  box-sizing: border-box;
  display: grid;
  gap: 26px;
  margin: 0 auto;
  max-width: 884px;
  padding: 20px 19px;
  width: 100%;
  z-index: 5;

  @include tablet() {
    align-items: center;
    border-color: transparent;
    gap: 28px;
    grid-auto-flow: column;
    justify-content: space-between;
    min-height: 128px;
    padding: 32px;
  }
}

.footer__logo {
  @include tablet() {
    align-items: center;
    gap: 8px;
    grid-column: 1;
  }
}
