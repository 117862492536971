@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.afs-logo {
  align-items: center;
  display: grid;
  gap: 6px;
  grid-template-columns: max-content max-content;
  justify-content: center;

  @include tablet() {
    justify-content: start;
  }
}

.afs-logo_page_login {
  .afs-logo__icon_text {
    color: $color-black-50;
  }

  @include tablet() {
    .afs-logo__icon_text {
      color: $color-black-5;
    }
  }
}

.afs-logo_page_any {
  .afs-logo__icon_text {
    color: $color-black-50;
  }
}

.afs-logo__icon {
  display: block;
  width: auto;
}

.afs-logo__icon_image {
  height: 28px;

  @include tablet() {
    height: 32px;
  }
}

.afs-logo__icon_text {
  height: 12px;

  @include tablet() {
    height: 14px;
  }
}
