@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.results-page {
  align-items: center;
  display: grid;
  grid-template-rows: 1fr max-content;
  height: 100%;
  justify-items: center;
}

.results-page__content {
  display: grid;
  gap: 48px;
  justify-items: center;
  padding: 8px 0 24px;

  @include tablet() {
    padding: 8px 0 0;
  }
}

.results-page__info {
  display: grid;
  gap: 32px;
  justify-items: center;
}

.results-page__icon {
  align-items: center;
  color: $color-theme-secondary;
  display: grid;
  padding: 9px;
}

.results-page__text-block {
  display: grid;
  gap: 16px;
  max-width: 360px;
  text-align: center;
}

.results-page__text {
  @include textSubtitle();
  padding: 0 16px;

  @include tablet() {
    padding: 0;
  }
}

.results-page__buttons {
  display: grid;
  gap: 16px;
  min-width: 240px;
}

.results-page__payment-button {
  padding: 16px 10px;
}

.results-page__back-button {
  padding: 10px 12px;
}
