@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.cash-form {
  align-content: start;
  display: grid;
  gap: 24px;

  @include tablet() {
    gap: 36px;
  }
}

.cash-form_hidden {
  display: none;
}

.cash-form__wrapper {
  background-color: $color-black-6;
  border-radius: 8px;
  padding: 12px;

  @include tablet() {
    padding: 20px;
  }
}

.cash-form__text {
  @include textBody();
  line-height: 24px;
  margin: 0;
  text-align: center;
}
