@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.stripe-card {
  align-content: start;
  display: grid;
  gap: 24px;

  @include tablet() {
    gap: 28px;
  }
}

.stripe-card_hidden {
  display: none;
}

.stripe-card__inputs {
  display: grid;
  grid-template-areas:
    "card card"
    "exp cvc";
  position: relative;
}

.stripe-card__input {
  border: 1px solid $color-black-35;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 16px;
  padding: 18px 12px;
}

.stripe-card__input_card {
  border-radius: 8px 8px 0 0;
  grid-area: card;
}

.stripe-card__input_expiry {
  border-radius: 0 0 0 8px;
  border-width: 0 1px 1px;
  grid-area: exp;
}

.stripe-card__input_cvc {
  border-radius: 0 0 8px;
  border-width: 0 1px 1px 0;
  grid-area: cvc;
}

.stripe-card__save-block {
  align-content: start;
  display: grid;
  gap: 20px;
}
