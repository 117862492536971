@import "src/assets/styles/variables.scss";
@import "src/assets/styles/animation.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.popup {
  align-items: center;
  animation: $transition-time linear forwards fadeOut;
  background-color: $color-black-0;
  box-sizing: border-box;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  left: 0;
  opacity: 0;
  padding: 64px 0 112px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;

  @include tablet() {
    padding-bottom: 100px;
  }

  @include desktop() {
    background-color: rgba($color: $color-black-0, $alpha: 0.5);
    padding-bottom: 0;
  }
}

.popup_hiding {
  animation: $transition-time linear both fadeIn;

  .popup__wrapper {
    @include desktop() {
      animation: $transition-time linear reverse both showFromTop;
    }
  }
}

.popup__wrapper {
  align-content: center;
  background-color: $color-black-0;
  box-sizing: border-box;
  display: grid;
  gap: 48px;
  height: 100%;
  justify-items: center;
  padding: 40px;
  width: 100%;

  @include desktop() {
    animation: $transition-time linear forwards showFromTop;
    border-radius: 8px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
    gap: 32px;
    height: auto;
    max-width: 520px;
  }
}

.popup__success-container {
  display: grid;
  gap: 16px;
  justify-items: center;
}

.popup__close-button {
  height: 20px;
  justify-self: end;
  position: relative;
  width: 20px;

  &::after,
  &::before {
    border-bottom: 1px solid $color-black-75;
    content: "";
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 15px;
  }

  &::after {
    transform: translateX(-7px) rotate(-45deg);
  }

  &::before {
    transform: translateX(4px) rotate(45deg);
  }
}

.popup__success-title {
  @include textH5-bold();
  margin: 0;
  padding: 0;
}

.popup__success-text {
  @include textSubtitle();
  text-align: center;

  @include tablet() {
    @include textBody();
  }
}

.popup__success-button {
  max-width: 240px;
  width: 100%;

  @include tablet() {
    max-width: 279px;
  }
}
