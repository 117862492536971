@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.option-pay-form_hidden {
  display: none;
}

.option-pay-form__intro {
  background-color: $color-black-6;
  border-radius: 8px;
  display: grid;
  gap: 16px;
  justify-items: center;
  padding: 12px;

  @include tablet() {
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 20px;
  }
}

.option-pay-form__intro-text {
  @include textBody();
  margin: 0;
  min-height: 24px;
}

.option-pay-form__intro-logo {
  height: 20px;
  width: 88px;
}
