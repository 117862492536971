@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.table_payments {
  .table__row {
    gap: 12px;
    grid-template-columns: 1fr 1fr 1fr 1fr 52px;
    padding-bottom: 16px;
    padding-top: 16px;

    @media (min-width: 330px) {
      gap: 16px;
    }

    @media (min-width: $details-table-breakpoint) {
      grid-template-columns: 1fr 1fr 1fr 1fr 66px;
    }

    @include tablet() {
      gap: 24px;
      padding-bottom: 22px;
      padding-top: 22px;
    }
  }

  .table__row_head {
    min-height: 48px;
    padding-bottom: 0;
    padding-top: 0;

    @include tablet() {
      min-height: unset;
      padding-bottom: 16px;
      padding-top: 16px;
    }
  }

  .table__th {
    align-self: center;
  }

  .table__cell {
    @include textRegular-12-mono();
    word-break: break-word;

    @include tablet() {
      font-size: $font-size-regular;
      line-height: 20px;
    }
  }

  .table__cell_status {
    font-weight: $font-weight-medium;
  }

  .table__cell_paid {
    color: $color-theme-assist;
  }

  .table__cell_missed {
    color: $color-red;
  }

  .table__cell_waiting {
    color: $color-black-30;
  }

  .table__cell_pending {
    color: $color-green-light;
  }
}

.payments-list__pagination {
  display: grid;
  gap: 12px;
  grid-auto-flow: column;

  @media (min-width: 330px) {
    gap: 16px;
  }

  .pagination__buttons {
    display: grid;
    gap: 8px;
    grid-auto-flow: column;
  }

  .pagination__button {
    background: $color-black-7;
  }

  .pagination__numbers {
    @include textAccent();
    color: $color-theme-primary;
    padding: 0;
  }
}
