@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/no-scrollbar.scss";
@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/text.scss";

.tabs {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;

  @include tablet() {
    padding: 0;
  }
}

.tabs_details {
  @include no-scrollbar();
  margin: -3px 0 0;
  overflow-x: auto;
  padding: 3px 16px 0;

  @include tablet() {
    margin: -3px -3px 0;
    padding: 3px 3px 0;
  }

  .tabs__wrapper {
    border-bottom: none;
    justify-content: start;

    &::after {
      content: "";
      height: 100%;
      width: 16px;
    }

    @media (min-width: $details-table-breakpoint) {
      justify-content: center;

      &::after {
        display: none;
      }
    }
  }

  .tabs__item {
    border-bottom: 1px solid $color-black-10;
    box-sizing: border-box;
    min-width: 128px;
    padding: 16px 12px;
  }

  .tabs__item_active {
    &::after {
      bottom: -1px;
    }
  }
}

.tabs__wrapper {
  align-content: start;
  align-items: stretch;
  border-bottom: 1px solid $color-black-10;
  box-sizing: border-box;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-content: center;
}

.tabs__item {
  @include reset-button();
  @include textBold-14();
  border-radius: 8px 8px 0 0;
  box-sizing: border-box;
  letter-spacing: 0.48px;
  min-height: 32px;
  padding: 10px 12px;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
  word-break: break-word;

  &:not(.tabs__item_active),
  &:not(.tabs__item_disabled) {
    cursor: pointer;
  }

  @media only screen and (any-pointer: fine) {
    &:hover {
      background-color: rgba($color-theme-assist-rgb, 0.08);
      color: $color-theme-secondary;
    }
  }

  @include tablet() {
    padding: 16px 12px;
  }
}

.tabs__item_active {
  color: $color-theme-primary;
  position: relative;

  &:active {
    background-color: rgba($color-theme-assist-rgb, 0.16);
  }

  @media only screen and (any-pointer: fine) {
    &:hover {
      background-color: rgba($color-theme-assist-rgb, 0.16);
    }
  }

  &::after {
    background-color: $color-theme-primary;
    border-radius: 2px 2px 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.tabs__item_disabled {
  color: $color-black-20;
  cursor: default;

  &::after {
    background-color: $color-black-20;
  }
}

.tabs__item_oversize {
  min-height: 52px;
}
