@import "variables.scss";

html {
  box-sizing: border-box;
}

body {
  background-color: $color-black-0;
  color: $color-black-75;
  font-family: $font-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100);
  min-width: 320px;
  padding: 0;
  text-size-adjust: none;
}
