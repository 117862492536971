@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.login-form {
  background-color: $color-black-0;
  box-sizing: border-box;
  width: 100%;
}

.login-form__head {
  display: grid;
  gap: 24px;
  text-align: center;

  @include tablet() {
    gap: 32px;
    text-align: start;
  }
}

.login-form__title-wrapper {
  display: grid;
  gap: 8px;
}

.login-form__title {
  @include textH5-bold();
  margin: 0;
}

.login-form__text {
  @include textAccent-regular();
  color: $color-black-50;
  line-height: 28px;
  margin: 0;

  @include tablet() {
    @include textAccent();
    color: $color-black-50;
  }
}

.login-form__text_code {
  @include textAccent-regular();
  color: $color-black-50;
  line-height: 28px;

  @include tablet() {
    @include textAccent();
    color: $color-black-50;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, max-content);
  }
}

.login-form__text-part_bold {
  align-items: center;
  display: flex;
  font-weight: 700;
  gap: 8px;
  justify-content: center;
}

.login-form__reset-button {
  background-color: $color-black-5;
  border-radius: 50%;
  color: $color-theme-primary;
  height: 24px;
  width: 24px;
}

.login-form__wrapper {
  background-color: $color-black-0;
  padding: 24px 16px;

  @include tablet() {
    padding: 0;
  }
}
