@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.next-payment {
  background: $color-black-2;
  border-radius: 8px;
  display: grid;
  gap: 12px;
  justify-items: center;
  padding: 16px 24px;
}

.next-payment__data {
  display: grid;
  gap: 4px;
  text-align: center;
}

.next-payment__data-label {
  @include textMedium-14();

  @include laptop() {
    @include textAccent-bold();
  }
}

.next-payment__data-value {
  @include textBold-20();
}

.next-payment__button {
  font-size: $font-size-medium;
  max-width: 240px;
  padding-bottom: 16px;
  padding-top: 16px;
  width: 100%;

  @include laptop() {
    font-size: $font-size-regular;
    padding-bottom: 9px;
    padding-top: 9px;
  }
}
