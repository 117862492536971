@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.table_services {
  .table__row {
    grid-template-columns: 1fr minmax(64px, max-content);
    padding: 16px;
  }

  .table__cell {
    @include textRegular-12-mono();
    align-self: center;
    word-break: break-all;

    @include tablet() {
      @include textBody-mono();
      align-self: start;
    }
  }

  .table__th,
  .table__cell {
    &:nth-child(2) {
      text-align: right;
    }
  }

  .table__cell_bold {
    font-weight: $font-weight-bold;
  }
}
