@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.labeled-value {
  align-items: center;
  display: inline-grid;
  gap: 4px;
  grid-auto-flow: column;
  justify-content: start;
}

.labeled-value__value_medium {
  font-weight: $font-weight-medium;
}

.labeled-value__value_bold {
  font-weight: $font-weight-bold;
}
