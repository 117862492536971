@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";

.pagination {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.pagination__numbers {
  @include textAccent();
  box-sizing: border-box;
  color: $color-theme-primary;
  min-width: 96px;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
}

.pagination__buttons {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: 8px;
}

.pagination__button {
  background-color: $color-black-0;
  border: 1px solid $color-black-10;
  border-radius: 50%;
  color: $color-theme-primary;
  display: grid;
  height: 40px;
  justify-content: center;
  width: 40px;

  &:disabled {
    background-color: $color-black-7;
    border: none;
    color: $color-black-20;
  }
}
