@import "src/assets/styles/mixins/breakpoints.scss";

.payment-method-form {
  padding-bottom: 8px;
  padding-top: 24px;

  @include tablet() {
    padding-top: 32px;
  }
}

.payment-method-form_bank-transfer {
  padding-bottom: 16px;

  @include tablet() {
    padding-bottom: 8px;
  }
}
