@import "../variables.scss";

@mixin textRegular-10 {
  color: $color-black-75;
  font-family: $font-main;
  font-size: 10px;
  font-style: normal;
  font-weight: $font-weight-regular;
}

@mixin textRegular-10-black-30 {
  @include textRegular-10();
  color: $color-black-30;
}

@mixin textRegular-10-black-50 {
  @include textRegular-10();
  color: $color-black-50;
}

@mixin textBold-10 {
  @include textRegular-10();
  font-weight: $font-weight-bold;
}

@mixin textRegular-12 {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-small;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: 16px;
}

@mixin textRegular-12-mono {
  @include textRegular-12();
  font-family: $mono-font;
}

@mixin textRegular-12-mono-bold {
  @include textRegular-12-mono();
  font-weight: $font-weight-bold;
}

@mixin textRegular-12-black-50 {
  @include textRegular-12();
  color: $color-black-50;
  letter-spacing: 0.24px;
}

@mixin textMedium-12 {
  @include textRegular-12();
  font-weight: $font-weight-medium;
  letter-spacing: 0.24px;
}

@mixin textMedium-12-black-5 {
  @include textRegular-12();
  color: $color-black-5;
  font-weight: $font-weight-medium;
}

@mixin textMedium-12-black-20 {
  @include textRegular-12();
  color: $color-black-20;
  font-weight: $font-weight-medium;
}

@mixin textMedium-12-black-30 {
  @include textRegular-12();
  color: $color-black-30;
  font-weight: $font-weight-medium;
  letter-spacing: 0.24px;
}

@mixin textRegular-14 {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-regular;
  font-style: normal;
  font-weight: $font-weight-regular;
  letter-spacing: 0.25px;
  line-height: 20px;
}

@mixin textRegular-14-black-50 {
  @include textRegular-14();
  color: $color-black-50;
}

@mixin textRegular-14-red {
  @include textRegular-14();
  color: $color-red;
}

@mixin textLight-14 {
  @include textRegular-14();
  font-weight: 300;
}

@mixin textBold-14 {
  @include textRegular-14();
  color: $color-black-75;
  font-weight: $font-weight-bold;
}

@mixin textBold-14-primary {
  @include textBold-14();
  color: $color-theme-primary;
}

@mixin textMedium-14 {
  @include textRegular-14();
  font-weight: $font-weight-medium;
}

@mixin textMedium-14-primary {
  @include textMedium-14();
  color: $color-theme-primary;
}

@mixin textRegular-16 {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-medium;
  font-style: normal;
  font-weight: $font-weight-regular;
}

@mixin textRegular-16-black-30 {
  @include textRegular-16();
  color: $color-black-30;
}

@mixin textLight-16 {
  @include textRegular-16();
  font-weight: 300;
}

@mixin textMedium-16 {
  @include textRegular-16();
  font-weight: $font-weight-medium;
}

@mixin textBold-16 {
  @include textRegular-16();
  font-weight: $font-weight-bold;
}

@mixin textBold-16-primary {
  @include textRegular-16();
  color: $color-theme-primary;
  font-weight: $font-weight-bold;
  letter-spacing: 0.3px;
  line-height: 24px;
}

@mixin textMedium-18 {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-extra-medium;
  font-style: normal;
  font-weight: $font-weight-medium;
}

@mixin textRegular-20 {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-big;
  font-style: normal;
  font-weight: $font-weight-regular;
}

@mixin textMedium-20 {
  @include textRegular-20();
  font-weight: $font-weight-medium;
}

@mixin textBold-20 {
  @include textRegular-20();
  font-weight: $font-weight-bold;
  letter-spacing: 0.25px;
  line-height: 28px;
}

@mixin textRegular-28 {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-headline-small;
  font-style: normal;
  font-weight: $font-weight-regular;
}

@mixin textBody {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-regular;
  font-weight: $font-weight-regular;
  letter-spacing: 0.25px;
  line-height: 20px;
}

@mixin textBody-medium {
  @include textBody();
  font-weight: $font-weight-medium;
}

@mixin textBody-bold {
  @include textBody();
  font-weight: $font-weight-bold;
}

@mixin textBody-mono {
  @include textBody();
  font-family: $mono-font;
  letter-spacing: 0;
}

@mixin textBody-mono-bold {
  @include textBody-mono();
  font-weight: $font-weight-bold;
}

@mixin textH5() {
  color: $color-black-90;
  font-family: $font-main;
  font-size: $font-size-huge;
  font-weight: $font-weight-regular;
  letter-spacing: 0.25px;
  line-height: 32px;
}

@mixin textH5-medium {
  @include textH5();
  font-weight: $font-weight-medium;
}

@mixin textH5-bold {
  @include textH5();
  font-weight: $font-weight-bold;
}

@mixin textAccent {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-medium;
  font-weight: $font-weight-regular;
  letter-spacing: 0.3px;
  line-height: 24px;
}

@mixin textAccent-regular {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-regular;
  font-weight: $font-weight-regular;
  letter-spacing: 0.15px;
  line-height: 24px;
}

@mixin textAccent-medium {
  @include textAccent();
  font-weight: $font-weight-medium;
}

@mixin textAccent-bold {
  @include textAccent();
  font-weight: $font-weight-bold;
}

@mixin textCaption {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
  letter-spacing: 0.24px;
  line-height: 16px;
}

@mixin textCaption-medium {
  @include textCaption();
  font-weight: $font-weight-medium;
}

@mixin textCaption-bold {
  @include textCaption();
  font-weight: $font-weight-bold;
}

@mixin textButton-oversize() {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-medium;
  font-weight: $font-weight-bold;
  letter-spacing: 0.48px;
  line-height: 20px;
}

@mixin textButton-medium() {
  @include textButton-oversize();
  font-size: $font-size-regular;
}

@mixin textButton-flattened() {
  @include textButton-oversize();
  font-size: $font-size-small;
}

@mixin textLabel {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-tiny;
  font-weight: $font-weight-regular;
  letter-spacing: 0.4px;
  line-height: 12px;
}

@mixin textSubtitle {
  color: $color-black-75;
  font-family: $font-main;
  font-size: $font-size-big;
  font-weight: $font-weight-regular;
  letter-spacing: 0.25px;
  line-height: 28px;
}

@mixin textSubtitle-medium {
  @include textSubtitle();
  font-weight: $font-weight-medium;
}

@mixin textSubtitle-bold {
  @include textSubtitle();
  font-weight: $font-weight-bold;
}
