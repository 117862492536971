@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.code-enter {
  align-content: start;
  display: grid;
  gap: 52px;

  @include tablet() {
    gap: 52px;
  }
}

.code-enter__data {
  align-content: start;
  display: grid;
  gap: 12px;
}

.code-enter__data-hint {
  @include textCaption();
  margin: 0;
  text-align: left;
  @include tablet() {
    grid-column: 1/3;
  }
}

.code-enter__data-hint-accent {
  text-decoration: underline;
}

.code-enter__data-hint-time {
  display: inline-block;
  font-family: $mono-font;
  font-weight: $font-weight-medium;
  min-width: 72px;

  @include tablet() {
    min-width: 84px;
  }
}

.code-enter__data-repeat-btn {
  @include textRegular-12();
  line-height: 143%;

  @include tablet() {
    font-size: 14px;
  }
}

.code-enter__buttons {
  display: grid;
  gap: 12px;
  grid-template-rows: 1fr 1fr;

  @include tablet() {
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
}
