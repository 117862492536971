@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.order-actions__container {
  background-color: $color-black-6;
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  padding: 16px 15px;

  @include tablet() {
    border-radius: 8px;
    padding-left: 20px;
  }
}

.order-actions__info {
  align-items: center;
  display: grid;
  gap: 4px;

  @include tablet() {
    gap: 12px;
    grid-auto-flow: column;
    justify-content: start;
  }
}

.order-actions__title {
  @include textMedium-14();

  @include tablet() {
    @include textBold-14();
  }
}

.order-actions__title_payment {
  @include tablet() {
    @include textAccent-bold();
  }
}

.order-actions__value {
  @include textSubtitle-bold();
}

.order-actions__button {
  justify-self: end;
  min-width: 140px;

  @include tablet() {
    font-size: $font-size-regular;
    min-height: 40px;
    min-width: 132px;
    padding: 10px;
  }
}
