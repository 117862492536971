@mixin reset-button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

@mixin reset-link {
  color: inherit;
  text-decoration: none;
}

@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin reset-list-item {
  margin: 0;
  padding: 0;
}

@mixin reset-heading {
  margin: 0;
  padding: 0;
}

@mixin reset-table {
  border-collapse: collapse;
  border-spacing: 0;
}

@mixin reset-table-cell {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
