@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.details-page-content {
  align-content: start;
  background: $color-black-0;
  border-radius: 8px;
  box-sizing: border-box;
  display: grid;
  gap: 24px;

  @media (min-width: $details-table-breakpoint) {
    gap: 32px;
  }
}
