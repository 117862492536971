@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.radiobox {
  position: relative;
  z-index: 2;
}

.radiobox__input {
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.radiobox__label {
  @include textAccent();
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 18px;
  grid-template-columns: 20px 1fr;
  letter-spacing: 0.15px;
  min-height: 24px;
  padding-left: 2px;
  word-break: break-word;

  @include tablet() {
    @include textBody();
    gap: 10px;
  }
}

.radiobox__icon {
  background-color: $color-black-0;
  border: 1px solid $color-black-35;
  border-radius: 50%;
  box-sizing: border-box;
  height: 20px;
  position: relative;
  transition: border-color $transition-time ease-in-out;
  width: 20px;
  z-index: 2;
}

.radiobox:hover:not(.radiobox_disabled) .radiobox__icon {
  border-color: $color-black-30;
}

.radiobox:focus-within:not(.radiobox_disabled) .radiobox__label,
.radiobox__input:focus-visible + .radiobox__label {
  &::before {
    background-color: $color-outline-background;
    border-radius: 50%;
    content: "";
    display: block;
    height: 26px;
    left: -1px;
    position: absolute;
    top: -1px;
    width: 26px;
    z-index: 1;
  }

  .radiobox__icon {
    border-color: $color-outline;
  }
}

.radiobox__input + .radiobox__label .radiobox__icon::after {
  background-color: $color-black-75;
  border-radius: 50%;
  content: "";
  display: block;
  height: 8px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transition: all ease-in-out $transition-time;
  width: 8px;
  z-index: 2;
}

.radiobox__input:checked + .radiobox__label .radiobox__icon::after {
  opacity: 1;
}

.radiobox__input:disabled {
  & + .radiobox__label {
    color: $color-black-30;
    cursor: default;
  }

  & + .radiobox__label .radiobox__icon {
    background-color: $color-black-5;
    border-color: $color-black-10;
  }

  & + .radiobox__label .radiobox__icon::after {
    background-color: $color-black-30;
  }
}
