@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/reset.scss";

.installment-payment-amount {
  align-content: start;
  display: grid;
}

.installment-payment-amount__title {
  @include textAccent-bold();
  color: $color-black-90;
  margin: 0;
  padding-bottom: 8px;
  text-transform: capitalize;

  @include tablet() {
    @include textSubtitle-bold();
    color: $color-black-90;
    padding-bottom: 10px;
    padding-top: 20px;
  }
}

.installment-payment-amount__down-text {
  @include textRegular-14-black-50();
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: 0;
  padding-bottom: 22px;
}

.installment-payment-amount__options-item-amount_disabled {
  color: $color-black-30;
}

.installment-payment-amount__hints {
  align-items: center;
  display: grid;
  gap: 17px;
  justify-self: start;
  padding: 11px 0 22px;

  @include tablet() {
    grid-auto-flow: column;
  }
}

.installment-payment-amount__hint {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  justify-self: start;
}

.installment-payment-amount__hint-text {
  @include textBody();
}

.installment-payment-amount__hint-btn {
  @include reset-button();
  @include textAccent-bold();
  box-sizing: border-box;
  color: $color-theme-primary;
  cursor: pointer;
  position: relative;

  &::before {
    border-bottom: 1px dashed $color-theme-secondary;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    opacity: 0.5;
    position: absolute;
    width: 100%;
  }
}
