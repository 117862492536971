@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/text.scss";

.orders-menu {
  @include reset-list();
  align-content: start;
  background-color: $color-black-0;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: grid;
  height: 276px;
  left: 0;
  max-height: 80vh;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  @include tablet() {
    max-width: 224px;
  }
}

.orders-menu__item-link {
  align-self: end;
  background-color: $color-black-0;
  border-top: 1px solid $color-black-10;
  bottom: 0;
  padding: 16px;
  position: sticky;
  text-align: start;

  @include tablet() {
    padding: 12px 16px;
    text-align: center;
  }
}

.orders-menu__link {
  @include reset-link();
  @include textCaption-bold();
  color: $color-theme-variant;
}

.orders-menu__user-controls {
  align-self: start;
  background-color: $color-black-6;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 15px 16px;

  @include tablet() {
    display: none;
  }
}

.orders-menu__user-controls-text {
  @include textAccent-medium();
}

.orders-menu__user-controls-wrapper {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
}

.orders-menu__user-controls-description {
  @include textCaption-bold();
  color: $color-theme-variant;
}

.orders-menu__user-controls-logout-button {
  color: $color-theme-variant;
}
