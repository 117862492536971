@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";

.input {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 52px;
  position: relative;
}

.input__field {
  @include textAccent();
  appearance: none;
  background: $color-black-0;
  border: 1px solid $color-black-35;
  border-radius: 8px;
  box-sizing: border-box;
  caret-color: inherit;
  margin: 0;
  outline: none;
  padding: 0 11px;
  transition: border-color $transition-time ease-in-out;
  width: 100%;

  &:hover:not(:disabled) {
    border-color: $color-black-60;
  }

  &:focus-within:not(:disabled) {
    border-color: $color-outline;
    box-shadow: 0 0 0 1px $color-outline;
  }
}

.input__placeholder {
  @include textRegular-16-black-30();
  left: 12px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.input:hover {
  border-color: $color-black-50;
}

.input_invalid {
  border-color: $color-red-light;

  .input__field {
    border-color: $color-red-light;
    color: $color-red;
  }
}

.input_invalid:hover {
  border-color: $color-red;
}

.input_disabled {
  background: $color-black-5;
  border-color: $color-black-5;
}

.input_disabled:hover {
  border-color: $color-black-5;
}

.input_disabled .input__field,
.input_disabled .input__value,
.input_disabled .input__placeholder {
  background: $color-black-5;
  color: $color-black-20;
}

.input_icon {
  gap: 8px;
  grid-template-columns: 1fr max-content;
}

.input .input__error-text_spaced {
  bottom: -27px;
}
