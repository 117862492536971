@import "src/assets/styles/mixins/breakpoints.scss";

.details-page {
  box-sizing: border-box;
  display: grid;
  height: 100%;
  margin: 0 auto;
  max-width: 884px;
  width: 100%;

  @include laptop() {
    padding-top: 32px;
  }
}

.details-page__wrapper {
  box-sizing: border-box;
  display: grid;
  gap: 24px;
  grid-template-rows: max-content 1fr auto;
  height: 100%;
  width: 100%;

  @include tablet() {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include laptop() {
    gap: 40px;
    grid-template-rows: minmax(140px, auto) 1fr auto;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
  }
}

.details-page__lot {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  padding: 16px 16px 0;

  @include laptop() {
    gap: 32px;
    grid-template-columns: 598px 190px;
    padding: 0;
  }
}
