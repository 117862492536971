@import "src/assets/styles/mixins/text.scss";

.record {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
}

.record__key {
  @include textAccent();
  word-break: break-word;
}

.record__value {
  @include textAccent-bold();
  align-items: center;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  word-break: break-word;
}

.record__value-item {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;

  &:not(:first-child)::before {
    border-left: 1px solid $color-black-10;
    content: "";
    height: 20px;
  }
}
