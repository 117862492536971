@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.documents-list {
  @include reset-list();
  align-items: start;
  display: grid;
  gap: 12px;
  padding: 0 16px;

  @media (min-width: 850px) {
    padding: 0;
  }
}

.documents-list__item {
  @include reset-list-item();
  align-items: center;
  background: $color-black-2;
  border-radius: 8px;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 100px;
  padding: 12px 16px;

  @include tablet() {
    gap: 24px;
    grid-template-columns: 20px 1fr minmax(100px, auto);
  }
}

.documents-list__item-info {
  display: grid;
  gap: 2px;
}

.documents-list__item-date {
  @include textRegular-12-black-50();
  letter-spacing: 0.24px;
}

.documents-list__item-name {
  @include textMedium-14-primary();
}

.documents-list__item-icon {
  color: $color-black-30;
  display: none;

  @include tablet() {
    display: block;
  }
}
