@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.footer-links {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;
  justify-items: center;

  @include tablet() {
    gap: 24px;
    min-height: 32px;
  }
}

.footer-links_page_login {
  @include tablet() {
    gap: 32px;
    padding: 40px 0;
  }
}

.footer-links__item {
  @include textBody-bold();
  color: $color-theme-primary;
  font-size: $font-size-small;
  opacity: 0.8;

  @include tablet() {
    @include textBody-bold();
    color: $color-theme-primary;
    position: relative;
  }
}
