// Font sizes
$font-size-extra-tiny: 8px;
$font-size-tiny: 11px;
$font-size-small: 12px;
$font-size-regular: 14px;
$font-size-medium: 16px;
$font-size-extra-medium: 18px;
$font-size-big: 20px;
$font-size-huge: 24px;
$font-size-headline-small: 28px;
$font-size-headline: 32px;
$font-size-headline-big: 52px;
$font-size-headline-huge: 64px;
$font-logo-main: 34px;
$font-logo-main-mobile-subtitle: 18px;

// Font weights
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Colors
$color-black-90: #222222;
$color-black-75: #3b3b3b;
$color-black-70: #4e4e4e;
$color-black-65: #595d61;
$color-black-60: #6f6f6f;
$color-black-50: #808080;
$color-black-40: #838383;
$color-black-35: #b0b0b0;
$color-black-30: #b9b9b9;
$color-black-25: #c2c2c2;
$color-black-20: #cdcdcd;
$color-black-10: #e5e5e5;
$color-black-7: #f4f6f5;
$color-black-6: #f6f6f6;
$color-black-5: #f7f7f7;
$color-black-2: #fafafa;
$color-black-0: #ffffff;
$color-green: #2b9529;
$color-green-light: #4cb051;
$color-green-background: rgba(
  $color: var(--color-theme-assist-rgb),
  $alpha: 0.08,
);
$color-red: #d95151;
$color-red-light: #f9919a;
$color-outline: #35cdfd;
$color-outline-background: #c6f1ff;

// Button focus
$button-focus: 0 0 0 1px $color-black-0, 0 0 0 3px $color-outline;

// Theme colors
$color-theme-accent: $color-black-75;
$color-theme-assist: var(--color-theme-assist);
$color-theme-assist-rgb: var(--color-theme-assist-rgb);
$color-theme-primary: var(--color-theme-accent);
$color-theme-secondary: var(--color-theme-secondary);
$color-theme-variant: var(--color-theme-variant);
$color-theme-light: var(--color-theme-light);

// Button filled
$color-public-btn-filled: var(--color-public-btn-filled);
$color-public-btn-filled-rgb: var(--color-public-btn-filled-rgb);
$color-public-btn-filled-hover: var(--color-public-btn-filled-hover);
$color-public-btn-filled-active: var(--color-public-btn-filled-active);

// Button ghost
$color-public-btn-ghost: var(--color-public-btn-ghost);
$color-public-btn-ghost-hover: var(--color-public-btn-ghost-hover);
$color-public-btn-ghost-active: var(--color-public-btn-ghost-active);

// Fonts
$font-main: "DM Sans", sans-serif;
$mono-font: "Roboto Mono", monospace;
$font-logo-title: "PT Sans Narrow", "Arial", sans-serif;
$font-logo-subtitle: "Mako", "Arial", sans-serif;

// Screens
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1366px;
$details-table-breakpoint: 550px;

// Transition
$transition-time: 0.2s;

// Sizes
$logo-max-width: 172px;
