@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/text.scss";

.instalments-list {
  display: grid;
  gap: 24px;
}

.instalments-list__content {
  display: grid;
}

.instalments-list__list {
  @include reset-list();
}

.instalments-list__list-item {
  @include reset-list-item();
  background-color: $color-black-0;
  box-sizing: border-box;
  display: grid;
  gap: 20px;
  grid-auto-columns: 14px 1fr auto;
  grid-auto-flow: column;
  padding: 12px 10px;

  &:nth-child(2n) {
    background-color: $color-black-2;
  }
}

.instalments-list__list-item-text {
  @include textBody-mono();

  &:last-child {
    text-align: right;
  }
}
