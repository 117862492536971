@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/reset.scss";

.order {
  @include reset-list-item();
  background-color: $color-black-0;
  border: 1px solid $color-black-10;
  border-radius: 8px;
  box-sizing: border-box;
  display: grid;
  gap: 0;
  max-width: 520px;
  overflow: hidden;
  width: 100%;
}

.order__wrapper {
  display: grid;

  @include tablet() {
    gap: 20px;
    padding: 19px 19px 20px;
  }
}

.order__info-container {
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr 80px;
  padding: 15px 11px 16px;

  @include tablet() {
    gap: 16px;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    padding: 0;
  }
}

.order__info {
  display: grid;
  gap: 12px;
  justify-items: start;

  @include tablet() {
    align-content: space-between;
    gap: 20px;
  }
}

.order__info-number {
  display: grid;
  gap: 12px;
  grid-auto-rows: max-content;

  @include tablet() {
    gap: 14px;
  }
}

.order__title-container {
  align-items: center;
  display: grid;
  gap: 2px;

  @include tablet() {
    gap: 12px;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
  }
}

.order__title {
  @include textSubtitle-bold();
  margin: 0;
}

.order__date {
  @include textMedium-12-black-30();

  @include tablet() {
    @include textBody-medium();
    color: $color-black-30;
  }
}

.order__lots {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4px;

  @include tablet() {
    padding-bottom: 0;
  }
}

.order__lot {
  @include textCaption-bold();

  &:not(:last-child) {
    padding-bottom: 8px;
    padding-right: 8px;
  }
}

.order__additional-data {
  @include textCaption-bold();
  margin: 0;
}

.order__details-button {
  color: $color-theme-primary;
  font-size: $font-size-regular;
  min-width: 132px;
  word-break: break-word;
}

.order__price-container {
  align-content: start;
  display: grid;
  gap: 8px;

  @include tablet() {
    gap: 12px;
  }
}

.order__price {
  display: grid;
  gap: 4px;
  justify-items: end;
  text-align: end;
}

.order__price-title {
  @include textCaption();

  @include tablet() {
    @include textBody();
  }
}

.order__price-value {
  @include textAccent-medium();
}

.order__details-accordion {
  border: 1px solid $color-black-10;
  padding: 16px 24px 0;
}
