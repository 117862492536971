@import "src/assets/styles/variables.scss";
@import "src/assets/styles/animation.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/focus.scss";
@import "src/assets/styles/mixins/text.scss";

$header-top-padding: 24px;
$header-side-padding: 32px;
$header-modal-gap: 16px;
$header-modal-toggle-width: 32px;

.header {
  background-color: $color-black-0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1002;
}

.header__wrapper {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  min-height: 64px;
  padding: 10px 16px;
  position: relative;

  @include tablet() {
    min-height: 72px;
    padding: $header-top-padding $header-side-padding 8px;
  }
}

.header__wrapper_without-modal {
  .header__logo {
    grid-column: 1;
  }

  @include tablet() {
    grid-template-columns: 1fr max-content;
    justify-items: end;

    .header__logo {
      grid-column: auto;
    }
  }
}

.header__logo {
  @include tablet() {
    left: 50%;
    position: absolute;
    top: $header-top-padding;
    transform: translateX(-50%);
  }
}

.header__menu {
  display: none;

  @include tablet() {
    align-items: center;
    box-sizing: border-box;
    display: grid;
    gap: 16px;
    grid-auto-flow: column;
  }
}

.header__menu-user {
  @include textBody-medium();
}

.header__menu-logout {
  background: $color-green-background;
  border-radius: 8px;
  height: 32px;
  width: 32px;
  z-index: 5;

  @include desktop() {
    &:hover,
    &:focus {
      opacity: 0.85;
    }
  }
}

.header__modal {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-auto-flow: column;
  grid-column: 2;
  grid-row: 1;
  min-height: 32px;
  min-width: 32px;
  position: relative;
  z-index: 5;

  @include tablet() {
    gap: $header-modal-gap;
    grid-column: auto;
    grid-row: auto;
  }
}

.header__modal-toggle {
  @include focus();
  background: transparent;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 32px;
  margin: 0;
  padding: 0;
  position: relative;
  width: $header-modal-toggle-width;

  .header__modal-toggle-particle {
    border-top: 1px solid $color-theme-primary;
    content: "";
    height: 10px;
    left: 8px;
    opacity: 1;
    position: absolute;
    top: 20px;
    transition: all $transition-time ease;
    width: 15px;
  }

  &::before {
    border-top: 1px solid $color-theme-primary;
    content: "";
    height: 10px;
    left: 8px;
    position: absolute;
    top: 10px;
    transition: all $transition-time ease;
    width: 15px;
  }

  &::after {
    border-top: 1px solid $color-theme-primary;
    content: "";
    height: 10px;
    left: 8px;
    position: absolute;
    top: 15px;
    transition: all $transition-time ease;
    width: 15px;
  }

  @include tablet() {
    background: $color-green-background;
  }
}

.header__modal-toggle_opened {
  pointer-events: none;

  .header__modal-toggle-particle {
    opacity: 0;
    top: 4px;
  }

  &::before {
    height: 12px;
    left: 12px;
    top: 13px;
    transform: rotate(-45deg);
    width: 17px;
  }

  &::after {
    height: 12px;
    left: 3px;
    top: 13px;
    transform: rotate(45deg);
    width: 17px;
  }
}

.header__modal-title {
  @include textButton-medium();
  color: $color-theme-primary;
  display: none;

  @include tablet() {
    display: block;
    max-height: 40px;
    max-width: calc(
      50vw - $logo-max-width / 2 - $header-side-padding * 2 -
        $header-modal-toggle-width - $header-modal-gap
    );
    overflow: hidden;
  }
}

.header__modal-title_hidden {
  opacity: 0;
  visibility: hidden;
}

.header__overlay {
  animation: $transition-time linear forwards fadeOut;
  background-color: rgba($color: $color-black-75, $alpha: 0.5);
  bottom: 0;
  content: "";
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1001;

  @include tablet() {
    display: none;
  }
}

.header__orders-menu {
  animation: $transition-time ease-out fadeOut;
  left: 0;

  @include tablet() {
    animation: none;
    left: 32px;
  }
}
