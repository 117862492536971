@use "sass:list";

$start-direction: left;

@function get-list-item($list, $index) {
  $length: list.length($list);

  @return list.nth($list, if($length >= $index, $index, $length));
}

@mixin flex-gap($gap, $child-selector: "*") {
  $row-gap: get-list-item($gap, 1);
  $column-gap: get-list-item($gap, 2);
  margin-#{$start-direction}: -$column-gap;
  margin-top: -$row-gap;

  > #{$child-selector} {
    margin-#{$start-direction}: $column-gap;
    margin-top: $row-gap;
  }
}
