@import "src/assets/styles/variables.scss";

@keyframes growHeight {
  0% {
    height: 0;
  }

  100% {
    height: 196px;
  }
}

.billing-address {
  align-content: start;
  animation: growHeight $transition-time forwards;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.billing-address__mid-inputs {
  align-content: start;
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
}

.billing-address__input {
  border-color: $color-black-35;
}
