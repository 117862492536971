@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.logo-text {
  align-content: start;
  display: grid;
  margin: 0;
  padding: 0;
}

.logo-text__title {
  font-family: $font-logo-title;
  font-style: normal;
  font-weight: $font-weight-bold;
  line-height: 1.1em;
  word-break: break-word;
}

.logo-text__subtitle {
  font-family: $font-logo-subtitle;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: 1.2em;
  word-break: break-word;
}

.logo-text_side {
  display: none;
  gap: 2px;
  justify-items: start;

  .logo-text__title {
    font-size: $font-size-regular;
  }

  .logo-text__subtitle {
    font-size: $font-size-extra-tiny;
  }

  @include tablet() {
    display: grid;
  }
}

.logo-text_bottom {
  gap: 4px;
  justify-items: center;
  text-align: center;

  .logo-text__title {
    font-size: $font-size-headline-small;
  }

  .logo-text__subtitle {
    font-size: $font-logo-main-mobile-subtitle;
  }

  @include tablet() {
    gap: 11px;
    max-width: 402px;

    .logo-text__title {
      font-size: $font-logo-main;
    }

    .logo-text__subtitle {
      font-size: $font-size-big;
    }
  }
}
