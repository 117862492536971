@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/flex-gap.scss";
@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/shadow-block.scss";
@import "src/assets/styles/mixins/text.scss";

.payment-form {
  align-content: start;
  box-sizing: border-box;
  display: grid;
  gap: 24px;
  padding: 16px 16px 28px;
  width: 100%;

  @include tablet() {
    box-sizing: content-box;
    max-width: 520px;
    padding: 32px 0 44px;
  }
}

.payment-form__title {
  @include reset-heading();
  @include textH5-bold();
  word-break: break-word;

  @include tablet() {
    @include textSubtitle-bold();
    color: $color-black-90;
  }
}

.payment-form__title-mobile {
  display: block;
  position: relative;
  top: 4px;

  @include tablet() {
    display: none;
  }
}

.payment-form__title-desktop {
  display: none;

  @include tablet() {
    display: block;
  }
}

.payment-form__order-data {
  box-sizing: border-box;
  display: grid;
  gap: 16px;
}

.payment-form__order-data-title {
  @include textRegular-20();
  line-height: 24px;
  margin: 0;
}

.payment-form__billing-address {
  display: grid;
  gap: 20px;
}

.payment-form__billing-address-title {
  @include textBold-14();
  margin: 0;
}

.payment-form__installment-payment-amount {
  border-bottom: 1px solid $color-black-10;
  border-top: 1px solid $color-black-10;
  padding: 24px 0;

  @include tablet() {
    border: 0;
    padding: 0;
  }
}

.payment-form__method {
  display: grid;
  gap: 8px;
}

.payment-form__method-content {
  display: grid;
}

.payment-form__method-title {
  @include reset-heading();
  @include textH5-bold();
  align-items: center;
  padding-bottom: 20px;
  word-break: break-word;

  @include tablet() {
    @include textSubtitle-bold();
    color: $color-black-90;
    padding-bottom: 24px;
    padding-top: 20px;
  }
}

.payment-form__method-options {
  display: grid;
  gap: 20px;

  @include tablet() {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include flex-gap(12px 16px);
  }
}

.payment-form__logos {
  align-items: center;
  display: grid;
  gap: 30px;
  grid-template-columns: max-content max-content;
  justify-content: center;

  @include tablet() {
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
}

.payment-form__logos-list {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, max-content);
  list-style: none;
  margin: 0;
  padding: 0;

  @include tablet() {
    gap: 16px;
  }
}

.payment-form__logos-stripe {
  @include textMedium-12-black-20();
  align-items: center;
  color: $color-black-20;
  display: grid;
  gap: 12px;
  grid-template-columns: max-content max-content;

  @include tablet() {
    font-size: 14px;
  }
}

.payment-form__logos-icon_visa {
  @include tablet() {
    height: 24px;
    width: 74px;
  }
}

.payment-form__logos-icon_amex {
  @include tablet() {
    height: 24px;
    width: 69px;
  }
}

.payment-form__logos-icon_mastercard {
  @include tablet() {
    height: 24px;
    width: 39px;
  }
}

.payment-form__logos-stripe-icon {
  @include tablet() {
    height: 24px;
    width: 58px;
  }
}

.payment-form__logo-plaid {
  display: flex;
  justify-content: center;
}

.payment-form__error {
  @include textRegular-14-red();
  margin: 0;
  padding: 20px 0 0;
  text-align: center;
}

.payment-form__data-list {
  border-bottom: none;
  gap: 24px;
  padding-bottom: 0;

  .data-list__pair {
    gap: 16px;
    grid-template-columns: 140px 1fr;
  }
}

.payment-form__tabs-content {
  display: grid;
  gap: 24px;
}

.payment-form__tabs {
  padding: 0;
}

.payment-form__shadow {
  align-items: center;
  background-color: rgba($color: $color-black-0, $alpha: 0.5);
  box-sizing: border-box;
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;

  @include tablet() {
    z-index: 1000;
  }
}
