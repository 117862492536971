@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.payment-form-actions {
  display: grid;
  gap: 16px;

  @include tablet() {
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    padding-top: 20px;
  }
}

.payment-form-actions_paypal {
  @include tablet() {
    grid-template-columns: 250px;
    justify-content: center;
  }
}

.payment-form-actions__button {
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  width: 100%;
}

.payment-form-actions__button_cancel {
  @include tablet-max() {
    font-size: $font-size-regular;
    min-height: 40px;
    padding: 9px 12px;
  }
}

.payment-form-actions__button_payment {
  @include tablet-max() {
    grid-row: 1;
  }
}
