@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/text.scss";

.app {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: calc(var(--vh, 1vh) * 100);
}

.app_err {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: unset;
  justify-content: center;
  padding-bottom: 80px;
}

.app__error-header {
  @include reset-heading();
  @include textH5-bold();
  color: $color-red;
}

.app__error-list {
  @include reset-list();
  padding: 30px 0;
}

.app__error-list-item {
  @include reset-list-item();
  @include textBody();
  color: $color-red;
  line-height: 30px;
  list-style-image: url("../../assets/icons/icon-arrow.svg");
  list-style-position: inside;

  &::marker {
    line-height: 0;
  }
}

.app__error-text {
  line-height: 20px;
  vertical-align: top;
}
