@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.order-info {
  display: grid;
  gap: 16px;

  @include laptop() {
    gap: 24px;
  }
}

.order-info__header {
  @include reset-heading();
  @include textH5-bold();
  text-align: center;
  word-break: break-word;

  @include laptop() {
    text-align: left;
  }
}

.order-info__br {
  @include laptop() {
    display: none;
  }
}

.order-info__content {
  display: grid;
  gap: 12px;
  justify-items: start;
}

.order-info__additional-info {
  @include textAccent();
}

.order-info__row {
  align-items: center;
  display: grid;
  gap: 12px;
  justify-items: center;
  width: 100%;

  @include laptop() {
    gap: 24px;
    grid-auto-flow: column;
    justify-items: start;
    width: auto;
  }
}

.order-info__row_property {
  .record {
    align-items: start;
    gap: 4px;
    grid-auto-flow: row;

    @include laptop() {
      gap: 8px;
      grid-auto-flow: column;
    }
  }

  .record__key {
    text-align: center;
  }

  .record__value {
    display: flex;
    flex-wrap: wrap;
    gap: 4px 12px;
    justify-content: center;

    @include laptop() {
      justify-content: start;
    }

    @supports not (translate: 10px) {
      // safari below 14.1, safari ios below 14.5
      gap: 0;
    }
  }

  .record__value-item {
    &:not(:first-child)::before {
      display: none;

      @include laptop() {
        display: block;
      }
    }

    @supports not (translate: 10px) {
      // safari below 14.1, safari ios below 14.5
      margin-bottom: 4px;
      margin-right: 10px;
    }
  }

  @include laptop() {
    justify-items: start;
  }
}
