@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";

.label {
  @include textCaption();
  background: $color-black-0;
  left: 7px;
  padding: 0 4px;
  position: absolute;
  top: -9px;
  transition: top $transition-time, padding $transition-time,
    font-size $transition-time, left $transition-time;
}

.label_select {
  top: -8px;
}

.label_checkbox {
  @include textRegular-14();
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  justify-content: start;
  padding: 0;
  position: static;
  text-transform: none;
  white-space: nowrap;
}

.label_placeholder {
  @include textAccent();
  color: $color-black-50;
  left: 12px;
  padding: 0;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
}

.label_placeholder-light {
  color: $color-black-30;

}

.label_invalid {
  color: $color-red;
}

.label_disabled {
  background: transparent;
  color: $color-black-20;
}

.label_valued-disabled {
  background: linear-gradient(to top, $color-black-5, $color-black-0);
  color: $color-black-20;
}
