@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/breakpoints.scss";

.property-list {
  @include reset-list();
  display: grid;

  .labeled-value__value {
    word-break: break-all;
  }
}

.property-list__item {
  @include reset-list-item();
  align-items: center;
  background: $color-black-0;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 12px 16px;

  @include tablet() {
    gap: 24px;
  }

  &:nth-child(2n) {
    background: $color-black-2;
  }
}

.property-list__item_header {
  padding-bottom: 16px;
  padding-top: 16px;
}

.property-list__item-lot {
  display: grid;
  gap: 4px;
}

.property-list__item-name {
  @include textBold-14-primary();
}

.property-list__item-properties {
  @include textRegular-12-black-50();
  display: flex;
  flex-wrap: wrap;
  gap: 4px 16px;

  @supports not (translate: 10px) {
    // safari below 14.1, safari ios below 14.5
    gap: 0;

    .labeled-value {
      margin-bottom: 4px;
      margin-right: 16px;
    }
  }
}

.property-list__item-price {
  @include textRegular-12-mono-bold();

  @include tablet() {
    @include textBody-mono-bold();
  }
}

.property-list__mausoleum-name {
  display: block;
  width: 100%;

  .labeled-value__label {
    padding-right: 4px;
  }
}

.property-list__header {
  @include textRegular-12-black-50();
}
