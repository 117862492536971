@import "src/assets/styles/mixins/text.scss";

.text-error {
  @include textCaption();
  bottom: -18px;
  color: $color-red;
  display: block;
  left: 0;
  position: absolute;
  text-align: left;
  z-index: 2;
}
