@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/text.scss";

.table {
  @include reset-table();
  display: grid;
}

.table__head,
.table__body {
  display: grid;
}

.table__row {
  display: grid;
  gap: 24px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  padding: 22px 16px;

  .table__body &:nth-child(2n + 1) {
    background-color: $color-black-2;
  }
}

.table__row_head {
  border-radius: 4px;
  padding: 16px;
}

.table__th {
  @include reset-table-cell();
  @include textRegular-12-black-50();
  text-align: start;
  word-break: break-word;
}

.table__cell {
  @include reset-table-cell();
  @include textBody-mono();
  text-align: start;
}
