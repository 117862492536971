@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/focus.scss";
@import "src/assets/styles/mixins/text.scss";

.button-icon {
  @include focus();
  align-items: center;
  background: none;
  border: none;
  box-sizing: border-box;
  color: $color-black-75;
  cursor: pointer;
  display: grid;
  height: 20px;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  width: 20px;

  &:hover:not(.button-icon_disabled),
  &:focus:not(.button-icon_disabled) {
    color: $color-black-70;
  }
}

.button-icon_link-tag-reset {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.button-icon_color_accent {
  color: $color-theme-accent;

  &:hover:not(.button-icon_disabled),
  &:focus:not(.button-icon_disabled) {
    color: $color-black-70;
  }
}

.button-icon_color_primary {
  color: $color-theme-primary;

  &:hover:not(.button-icon_disabled),
  &:focus:not(.button-icon_disabled) {
    opacity: 0.75;
  }
}

.button-icon_disabled {
  color: $color-black-10;
  cursor: default;
  pointer-events: none;
}

.button-icon_size_xs {
  height: 20px;
  width: 20px;

  & svg {
    height: 16px;
    width: 16px;
  }
}

.button-icon_size_sm {
  height: 20px;
  width: 20px;

  & svg {
    height: inherit;
    width: inherit;
  }
}

.button-icon_size_md {
  height: 30px;
  width: 30px;

  & svg {
    height: 20px;
    width: 20px;
  }
}

.button-icon_size_lg {
  height: 30px;
  width: 30px;

  & svg {
    height: 30px;
    width: 30px;
  }
}
