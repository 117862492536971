@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/focus.scss";
@import "src/assets/styles/mixins/reset.scss";

.logo {
  @include focus();
  @include reset-link();
  align-content: start;
  align-items: center;
  display: grid;
}

.logo__img {
  height: 32px;
}

.logo_side {
  color: $color-theme-primary;
  gap: 8px;
  grid-auto-flow: column;
  justify-items: start;
  max-width: 230px;

  .logo__img {
    height: 40px;
    max-width: $logo-max-width;
    width: auto;
  }

  .logo__img_with-text {
    height: 40px;
    width: 40px;
  }
}

.logo_medium {
  display: none;

  @include tablet() {
    display: grid;
  }
}

.logo_bottom {
  align-content: center;
  color: $color-black-5;
  gap: 11px;
  justify-items: center;
  min-height: 120px;

  @include tablet() {
    min-height: 190px;
  }

  .logo__img {
    height: 130px;
    max-width: 160px;
    width: auto;

    @include tablet() {
      height: 190px;
      max-width: 190px;
    }
  }

  .logo__img_with-text {
    height: 85px;
    max-width: 80px;

    @include tablet() {
      height: 110px;
      max-width: 100px;
    }
  }
}

.logo_small-side {
  display: grid;

  @include tablet() {
    display: none;
  }
}

.logo_colored {
  color: $color-theme-primary;
}
