@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/focus.scss";

.button-link {
  @include focus();
  @include textRegular-14();
  background: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
  transition: color ease $transition-time;
}

.button-link_link-tag-reset {
  text-decoration: none;
}

.button-link_accent {
  font-size: 16px;
}

.button-link_weight_bold {
  font-weight: 600;

  &.button-link_accent {
    font-weight: 700;
  }
}

.button-link_color_accent {
  color: $color-theme-accent;
}

.button-link_color_secondary {
  color: $color-theme-primary;
}

.button-link_disabled {
  color: $color-black-10;
  cursor: default;
  pointer-events: none;
}

.button-link_dashed {
  border-bottom: 1px dashed currentColor;

  &:hover,
  &:focus,
  &.button-link_disabled {
    border: none;
  }
}

.button-link_underline {
  text-decoration: underline;

  &:hover,
  &:focus,
  &.button-link_disabled {
    text-decoration: none;
  }
}

.button-link_uppercase {
  text-transform: uppercase;
}

.button-link_spaced {
  min-height: 40px;
}
