@import "src/assets/styles/variables.scss";

@keyframes loader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.loader {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 100vw;
  padding: 10px;
  width: 100%;
}

.loader_position_right {
  height: 100%;
  justify-content: flex-end;
  padding: 0;
  position: absolute;
  z-index: 20;

  .loader__spinner {
    height: 100%;
    width: auto;
  }
}

.loader_align_left {
  justify-content: flex-start;
}

.loader_position_overlay {
  background-color: rgba($color: $color-black-5, $alpha: 0.5);
  height: 100%;
  left: 0;
  padding: 0 0 30vh;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}

.loader__spinner {
  animation: loader-rotate 2s linear infinite;
  height: 50px;
  width: 50px;
}

.loader__spinner-path {
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke: $color-theme-primary;
  stroke-linecap: round;
}

.loader_small {
  padding: 0;

  .loader__spinner {
    height: 20px;
    width: 20px;
  }
}

.loader_medium {
  padding: 0;

  .loader__spinner {
    height: 40px;
    width: 40px;
  }
}
