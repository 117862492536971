@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/reset.scss";
@import "src/assets/styles/mixins/text.scss";

.data-list {
  border-bottom: 1px solid $color-black-10;
  display: grid;
  gap: 16px;
  padding-bottom: 24px;

  @include tablet() {
    border-bottom: 1px solid $color-black-10;
    padding-bottom: 40px;
  }
}

.data-list_spaced {
  gap: 16px;

  .data-list__pairs {
    gap: 12px;
  }

  .data-list__pair-value {
    align-self: center;
  }
}

.data-list_services {
  gap: 4px;

  @include tablet() {
    gap: 8px;
  }
}

.data-list__title {
  @include textSubtitle-bold();
  @include reset-heading();
  word-break: break-word;
}

.data-list__pairs-wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}

.data-list__pairs {
  display: grid;
  gap: 12px;

  @include tablet() {
    gap: 8px;
  }
}

.data-list__pair {
  align-items: start;
  display: grid;
  gap: 4px;

  @include tablet() {
    gap: 14px;
    grid-template-columns: 140px 1fr;
  }
}

.data-list__pair-key,
.data-list__pair-value {
  @include textBody();
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-list__pair-key {
  align-items: center;
  display: grid;
  flex-shrink: 0;
}

.data-list__pair-value {
  flex-shrink: 1;
  font-weight: $font-weight-medium;
  overflow-wrap: break-word;
  text-align: left;
}

.data-list__lines {
  list-style: none;
  margin: 0;
  padding: 0;
}

.data-list__line {
  @include textRegular-14();
  align-items: center;
  border-bottom: 1px solid $color-black-10;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr max-content;
  padding-bottom: 8px;
  padding-top: 8px;

  @include tablet() {
    gap: 16px;
    padding-bottom: 12px;
    padding-top: 12px;
  }
}

.data-list__line_with-badge {
  grid-template-columns: 1fr max-content minmax(60px, auto);
}

.data-list__line-value {
  text-align: right;
}

.data-list__line_no-border {
  border-color: transparent;
}

.data-list__pair-value_bold,
.data-list__line-value_bold {
  font-weight: $font-weight-bold;
}

.data-list__line-value_total {
  font-size: 16px;
}

.data-list__line-badge {
  @include textMedium-12();
  background-color: $color-black-5;
  border-radius: 12px;
  color: $color-green;
  line-height: 16px;
  padding: 2px 10px;
}

.data-list_plan {
  border-bottom: none;
  padding-bottom: 0;

  .data-list__pair-value_bold {
    @include textAccent-bold();
  }
}

.data-list_cemetery {
  .data-list__pairs {
    gap: 12px;
  }
}

.data-list__putted-list-title {
  @include textSubtitle-bold();
  @include reset-heading();
  word-break: break-word;
}

.data-list__putted-list {
  border-bottom: 1px solid $color-black-10;
  display: grid;
  gap: 16px;
  padding-bottom: 24px;

  @include tablet() {
    padding-bottom: 40px;
  }

  .data-list__pairs {
    gap: 8px;
  }

  .data-list__pair {
    grid-template-columns: 112px 1fr;

    @include tablet() {
      grid-template-columns: 100px 1fr;
    }
  }

  .data-list {
    background-color: $color-black-2;
    border-bottom: none;
    gap: 12px;
    padding: 16px;
  }

  .data-list__title {
    @include textAccent-bold();
    line-height: 32px;
  }

  .data-list_total {
    background-color: $color-black-0;
    padding: 8px 0 0;

    .data-list__pair {
      grid-template-columns: 1.4fr 0.6fr;

      @include tablet() {
        grid-template-columns: 1fr 1fr;
      }
    }

    .data-list__pair:last-child {
      .data-list__pair-key,
      .data-list__pair-value {
        @include textSubtitle-medium();
      }

      .data-list__pair-value {
        font-weight: $font-weight-bold;
      }
    }

    .data-list__pair-key,
    .data-list__pair-value {
      @include textBody-bold();
    }

    .data-list__pair-value {
      text-align: right;
    }
  }
}

.data-list_empty {
  gap: 0;
}
