@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/reset.scss";

.order-accordion {
  display: grid;
}

.order-accordion__button {
  @include reset-button();
  border-bottom: 1px solid transparent;
  border-top: 1px solid $color-black-10;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 11px;
  position: relative;
  text-align: left;

  @include tablet() {
    padding: 15px 23px 14px;
  }

  .order-accordion_opened & {
    border-bottom: 1px solid $color-black-10;
  }
}

.order-accordion__button-text {
  @include textBody-medium();
}

.order-accordion__button-icon {
  color: $color-theme-primary;
  transform: rotate(90deg);
  transition: transform $transition-time ease;

  .order-accordion_opened & {
    transform: rotate(-90deg);
  }
}

.order-accordion__dropdown {
  display: grid;
}

.order-accordion__list {
  @include reset-list();
  display: grid;
}

.order-accordion__list-item {
  @include reset-list-item();
  background-color: $color-black-0;
  display: grid;
  gap: 24px;
  grid-auto-flow: column;
  grid-template-columns: 1.2fr 0.64fr 0.72fr;
  justify-content: space-between;
  padding: 12px;

  @include tablet() {
    gap: 20px;
    grid-template-columns: 120px 92px 100px;
    padding: 20px 24px;
  }

  &:nth-child(2n) {
    background-color: $color-black-2;
  }
}

.order-accordion__list-item_missed {
  .order-accordion__list-item-text {
    color: $color-red;
  }
}

.order-accordion__list-item-text {
  @include textRegular-12-mono();
  line-height: 20px;

  @include tablet() {
    @include textBody-mono();
  }
}

.order-accordion__list-item-text_status {
  font-weight: $font-weight-medium;
  letter-spacing: 0.25px;
  text-align: end;
  word-break: break-word;
}

.order-accordion__list-item-text_paid {
  color: $color-theme-assist;
}

.order-accordion__list-item-text_missed {
  color: $color-red;
}

.order-accordion__list-item-text_current {
  color: $color-black-75;
}

.order-accordion__list-item-text_waiting {
  color: $color-black-30;
}

.order-accordion__list-item-text_pending {
  color: $color-green-light;
}

.order-accordion__pagination {
  padding-bottom: 30px;
  padding-top: 24px;
}
