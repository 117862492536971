@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";
@import "src/assets/styles/mixins/reset.scss";

.order-item {
  background-color: $color-black-0;
  border-bottom: 1px solid $color-black-10;
  padding: 12px 16px 11px;
  position: relative;

  &:hover,
  &:focus,
  &:focus-within {
    background-color: $color-black-2;
  }

  @include tablet() {
    padding: 16px;
  }
}

.order-item__content {
  @include reset-link();
  display: grid;
  justify-items: start;
}

.order-item__title {
  @include textBody-bold();
  margin: 0;
  padding-bottom: 8px;
  text-transform: uppercase;
}

.order-item__number-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 36px);
}

.order-item__number {
  @include textLabel();
  margin: 0;
  padding-bottom: 8px;
  word-break: break-word;

  &:not(:last-child) {
    padding-right: 8px;
  }
}

.order-item__status {
  @include textMedium-12();
  background: $color-black-6;
  border-radius: 2px;
  color: $color-theme-assist;
  padding: 4px 8px;
  word-break: break-word;
}

.order-item__arrow {
  color: $color-theme-primary;
  height: 20px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}
