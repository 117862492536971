@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.phone-enter {
  align-content: start;
  display: grid;
  gap: 20px;

  @include tablet() {
    gap: 16px;
  }
}

.phone-enter__container {
  display: grid;
  gap: 8px;

  @include tablet() {
    gap: 12px;
    padding-top: 0;
  }
}

.phone-enter__terms,
.phone-enter__terms-link {
  @include textBody();

  @include tablet() {
    @include textAccent();
  }
}

.phone-enter__terms-link {
  color: $color-theme-light;
  font-weight: $font-weight-medium;
  text-decoration: none;
}

.phone-enter__checkbox {
  justify-self: start;
  padding: 2px;

  @include tablet() {
    padding: 0;
  }
}

.phone-enter__error {
  position: static;
}

.phone-enter__submit-btn {
  @include tablet() {
    max-width: 192px;
  }
}
