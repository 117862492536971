@import "src/assets/styles/variables.scss";

@mixin tablet-max {
  @media (max-width: ($tablet-width - 1px)) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}

@mixin laptop-max {
  @media (max-width: ($laptop-width - 1px)) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $laptop-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}
