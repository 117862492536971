@import "src/assets/styles/variables.scss";
@import "src/assets/styles/mixins/breakpoints.scss";
@import "src/assets/styles/mixins/text.scss";

.signing-page {
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  position: relative;
  width: 100%;
}

.signing-page__title {
  @include textH5-bold();
  margin: 0 auto;
  padding: 0;
  text-align: left;
  word-break: break-word;

  @include tablet() {
    margin: 0;
    text-align: center;
  }
}

.signing-page__title-number {
  white-space: nowrap;
}

.signing-page__wrapper {
  display: grid;
  gap: 24px;
  grid-auto-rows: max-content;
  padding: 16px;

  @include tablet() {
    gap: 40px;
    margin: 0 auto;
    max-width: 520px;
    padding: 53px 0 32px;
  }
}

.signing-page__content {
  @include tablet() {
    border: 1px solid $color-black-10;
    border-radius: 8px;
    padding: 32px;
  }
}

.signing-page__action {
  background-color: $color-black-0;
  border-radius: 8px;
  bottom: 26px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  justify-items: center;
  margin: 0 auto 16px;
  max-width: 328px;
  min-height: 120px;
  padding: 16px;
  position: sticky;
  width: 100%;
  z-index: 10;

  @include tablet() {
    bottom: 24px;
    margin: 0 auto;
    max-width: 372px;
  }
}

.signing-page__action-text {
  @include textBody-bold();
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

.signing-page__action-button {
  width: 100%;
}
